var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "text-center",
    },
    [
      _c("img", {
        staticClass: "m-5",
        attrs: {
          src: require("@/assets/no-contract.png"),
        },
      }),
      _c(
        "h3",
        {
          staticClass: "text-secondary",
        },
        [
          _vm._v(_vm._s(_vm.$t("wrongMes"))),
          _c("br"),
          _vm._v(_vm._s(_vm.$t("callingHelp"))),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }